<template>
  <div class="app-container" v-loading="loading">
    <el-form ref="form" label-width="300px">
      <span>Get Receipt by UserId</span>

      <MyFormItem label="UserId" tooltip="" >
        <el-input class="form-input" v-model="listReceiptByUserId.userId" size="normal"/>
      </MyFormItem>

      <el-button type="primary" @click="onTapGetListReceipt()">
        Get List Receipt
      </el-button>

      <el-pagination
          background layout="prev, pager, next"
          :total="listReceiptByUserId.total"
          :page-size="listReceiptByUserId.limit"
          :current-page="listReceiptByUserId.page"
          @current-change="onListReceiptPageChange"
          v-if="listReceiptByUserId.listData.length > 0"
      />
      <el-table :data="listReceiptByUserId.listData" border v-if="listReceiptByUserId.listData.length > 0">
        <el-table-column
            prop="OrderId"
            label="OrderId"/>
        <el-table-column
            prop="TimeLog"
            label="TimeLog (DD-MM-YYYY HH:mm:ss)"/>
        <el-table-column
            prop="ProductId"
            label="ProductId"/>
        <el-table-column
            prop="PurchaseToken"
            label="PurchaseToken"/>
        <el-table-column
            label="Command"
        >
          <template #default="scope">
            <el-button type="primary" round @click="handleTapGetDetail(scope.row)">
              Get Detail
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <br/>
      <br/>

      <div ref="receipt-info">
        <span>Get Receipt info</span>

        <MyFormItem label="OrderId" tooltip="" >
          <el-input class="form-input" v-model="receiptByOrderId.orderId" size="normal"/>
        </MyFormItem>

        <el-button type="primary" @click="getReceiptInfo()">
          Get Receipt info
        </el-button>

        <el-table :data="receiptByOrderId.result" border v-if="receiptByOrderId.result.length > 0">

          <el-table-column
              prop="OrderId"
              label="OrderId"/>
          <el-table-column
              prop="UserId"
              label="UserId"/>
          <el-table-column
              prop="TimeLog"
              label="TimeLog (DD-MM-YYYY HH:mm:ss)"/>
          <el-table-column
              prop="ProductId"
              label="ProductId"/>
          <el-table-column
              prop="PurchaseToken"
              label="PurchaseToken"/>
        </el-table>

        <br/>
        <br/>
        <json-viewer :value="receiptByOrderId.payloadJson" v-if="receiptByOrderId.payloadJson !== null"></json-viewer>

      </div>

    </el-form>
  </div>
</template>

<script>
import MyFormItem from "@/views/loop-machine/component/myFormItem.vue";
import request from "@/utils/request-service-base";
import {Message} from "element-ui";
import JsonViewer from 'vue-json-viewer'
import moment from "moment/moment";

export default {
  components: {
    MyFormItem,
    JsonViewer,
  },
  data: () => {
    return {
      listReceiptByUserId: {
        userId: "",
        pageInput: 1,
        listData: [],
        total: 0,
        page: 0,
        limit: 0,
      },

      receiptByOrderId: {
        orderId: "",
        result: [],
        payloadJson: null,
      },
      loading: false,
    }
  },
  methods: {
    scrollMeTo(refName) {
      let element = this.$refs[refName];
      let top = element.offsetTop;

      window.scrollTo(0, top);
    },

    formatDateSecondToLocal(time) {
      return `${moment.unix(time).local().format("DD-MM-YYYY HH:mm:ss")}`;
    },

    formatDateMilliSecondToLocal(time) {
      return this.formatDateSecondToLocal(time * 0.001);
    },

    async getReceiptByUserId() {
      try {
        this.listReceiptByUserId.listData = [];

        this.loading = true;
        let response = await request({
          url: `/maintain/verify/get-receipt-by-userid/${this.listReceiptByUserId.userId}?page=${this.listReceiptByUserId.pageInput}`,
          method: `get`,
        });

        this.loading = false;

        let dataResponse = response.data;

        this.listReceiptByUserId.listData = dataResponse.data;
        this.listReceiptByUserId.total = dataResponse.paging.total;
        this.listReceiptByUserId.limit = dataResponse.paging.limit;
        this.listReceiptByUserId.page = parseInt(dataResponse.paging.page);

        this.listReceiptByUserId.pageInput = this.listReceiptByUserId.page;

        for (let i = 0; i < this.listReceiptByUserId.listData.length; i++) {
          let data = this.listReceiptByUserId.listData[i];
          data.TimeLog = this.formatDateSecondToLocal(data.TimeLog);
        }
      }
      catch (ex) {
        Message({
          message: `Get receipt error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.loading = false;
      }
    },

    async onTapGetListReceipt() {
      this.listReceiptByUserId.pageInput = 1;
      await this.getReceiptByUserId();
    },

    async onListReceiptPageChange(val) {
      this.listReceiptByUserId.pageInput = val;
      await this.getReceiptByUserId();
    },

    async getReceiptInfo(){
      try {
        this.receiptByOrderId.result = [];
        this.receiptByOrderId.payloadJson = "";
        this.loading = true;
        let responseReceipt = await request({
          url: `/maintain/verify/get-receipt/${this.receiptByOrderId.orderId}`,
          method: `get`,
        });

        this.loading = false;

        let responseData = responseReceipt.data;
        let receiptData = {
          OrderId: responseData.Data.OrderId,
          UserId: responseData.Data.UserId,
          TimeLog: this.formatDateSecondToLocal(responseData.Data.TimeLog),
          ProductId: responseData.Data.ProductId,
          PurchaseToken: responseData.Data.PurchaseToken,
        };

        this.receiptByOrderId.result.push(receiptData);

        if (responseData.Payload.purchaseTimeMillis !== undefined) {
          responseData.Payload.purchaseTimeMillis = this.formatDateMilliSecondToLocal(responseData.Payload.purchaseTimeMillis);
        }

        if (responseData.Payload.expiryTimeMillis !== undefined) {
          responseData.Payload.expiryTimeMillis = this.formatDateMilliSecondToLocal(responseData.Payload.expiryTimeMillis);
        }

        if (responseData.Payload.startTimeMillis !== undefined) {
          responseData.Payload.startTimeMillis = this.formatDateMilliSecondToLocal(responseData.Payload.startTimeMillis);
        }

        this.receiptByOrderId.payloadJson = responseData.Payload;
      }
      catch (ex) {
        Message({
          message: `Get receipt info error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.loading = false;
      }
    },

    async handleTapGetDetail(row) {
      try {
        this.receiptByOrderId.orderId = row.OrderId;
        await this.getReceiptInfo();

        this.scrollMeTo("receipt-info");
      }
      catch (ex) {
        Message({
          message: `Get receipt detail error: ${ex}`,
          type: "error",
          duration: 5 * 1000,
        });
        this.loading = false;
      }
    },
  }
}
</script>